import React, { useState } from 'react'
function BlogCategory({
  categories = [],
  setBlogState = () => {
    console.log('Set Blog State !')
  },
  allBlogs = []
}) {
  const [allCategories, setAllCategories] = useState(categories)
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState('')

  const selectedAndFilterBlog = (e) => {
    const curVal = e.target.innerHTML.trim()

    if (currentSelectedCategory === curVal) {
      setCurrentSelectedCategory('')
      setBlogState([...allBlogs])
    } else {
      setCurrentSelectedCategory(curVal)
      setBlogState(() => {
        let filteredBlogs = [...allBlogs].filter((blog) => {
          const categoryListToCheck = blog?.node?.frontmatter?.categories
          if (categoryListToCheck) {
            return categoryListToCheck.includes(curVal)
          }
          return false
        })

        return filteredBlogs
      })
    }

    setAllCategories((prev) => prev)

    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }
  return (
    <div className="blogs__category__container">
      <div className="blogs_category__title">Category</div>
      <hr style={{ color: '#484848' }} />

      {allCategories.map((cat) => (
        <p
          onClick={selectedAndFilterBlog}
          className={`${
            cat?.node?.frontmatter?.title === currentSelectedCategory
              ? 'blogs___categoriestitles'
              : 'catgoery____headings'
          }`}
        >
          {cat?.node?.frontmatter?.title || 'No Tag'}
        </p>
      ))}
    </div>
  )
}

export default BlogCategory
